<template>
  <router-view />
</template>

<script>
import BaseMixin from '@/baseMixin'
import { mapActions } from 'vuex'

export default {
  mixins: [BaseMixin],
  methods: {
    ...mapActions('storePayment', {
      forgePaymentStates: 'forgeStates',
    }),
    ...mapActions('storeHoliday', {
      forgeHolidayStates: 'forgeStates',
    }),
  },
  created () {
    this.forgePaymentStates().catch(this.handleError)
    this.forgeHolidayStates().catch(this.handleError)
  },
}
</script>
